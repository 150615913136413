<template>
<div>
  <Header
    :category="category"
    @toggle-menu-show="$emit('toggle-menu-show', $event)"
  />
  <main>
  <p class="back-link" @click="$router.back()">Retour en arrière</p>
  </main>
  <Product
    v-for="product in sortedProducts"
    :key="product.id"
    :product="product"
  />
  <ProductPageNavigation />
</div>
</template>

<script>
import ProductPageNavigation from "../components/ProductPageNavigation.vue";
import Header from "../components/CategoryPage/Header.vue";
import Product from "../components/CategoryPage/Product.vue";
import jsonData from "../data.json";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CategoryPage",
  components: { ProductPageNavigation, Header, Product },
  emits: ["toggle-menu-show"],
  data() {
    return {
      productInfo: jsonData,
    };
  },
  computed: {
    category() {
      return this.$route.params.category;
    },
    currentProducts() {

      console.log("this.category", this.category);
      return this.productInfo.filter(
          (product) => product.category === this.category.toString().toLowerCase()
      );
    },
    sortedProducts() {
      return this.currentProducts.slice().sort((a, b) => b.new - a.new);
    },
  },
};
</script>

<style lang="scss" scoped>

main {
  width: 32.7rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    width: 111rem;
  }
}

.back-link {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: #7d7d7d;
  margin: 1.6rem 0 2.4rem 0;
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #e91e63;
  }

  @media (min-width: 768px) {
    margin-top: 3.3rem;
  }

  @media (min-width: 1205px) {
    margin-top: 7.9rem;
  }
}
</style>
