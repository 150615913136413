import VueRouter from 'vue-router'
import CategoryPage from "@/pages/CategoryPage";
import ProductPage from "@/pages/ProductPage";
import Checkout from "@/pages/Checkout";
import Home from "@/pages/Home";
import CGU from "@/pages/CGU";


const routes = [
  { path: "/", name: "Home", component: Home },
  {
    path: "/category/:category",
    name: "CategoryPage",
    component: CategoryPage,
  },
  {
    path: "/product/:product",
    name: "ProductPage",
    component: ProductPage,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/mentions-legales",
    name: "CGU",
    component: CGU,
  },
];


// Create a router.
const router = new VueRouter({
  //mode: 'history',
  // Inject routes in router.
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0, behavior: 'smooth',}
  },
});

export default router;
