<template>
<div>
  <section class="categories">
    <Category product="Méchouis" />
    <Category product="Poêlés" />
    <Category product="Apéros" />
    <Category product="Menus" />
    <Category product="Friteries" />
    <Category product="Desserts" />
  </section>
  <AboutSection />
</div>
</template>

<script>
import Category from "../components/Homepage/Category.vue";
import AboutSection from "../components/Homepage/AboutSection.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProductPageNavigation",
  components: { Category, AboutSection },
};
</script>

<style lang="scss" scoped>
.categories {
  display: flex;
  flex-wrap: wrap;
  padding: 9.2rem 2.4rem 0 2.4rem;

  @media (min-width: 768px) {
    justify-content: center;
    padding: 9.6rem 4rem 0 4rem;

    & > * {
      flex: 1 1 calc(50% - 1rem);
      margin: 0.5rem;
    }
  }

  @media (min-width: 1205px) {
    padding: 12rem 16.5rem 0 16.5rem;

    & > * {
      flex: 1 1 calc(33.333% - 2rem);
      margin: 1rem;
    }
  }
}
</style>
