import Vue from 'vue'
import App from "./App.vue";
import router from "./router";
import VueRouter from 'vue-router';

//createApp(App).use(router).mount("#app");
Vue.use(VueRouter);


new Vue({
  router,
  render: h => h(App)
  }).$mount('#app')