<template>
  <article class="preview">
    <h2 class="preview__header">Prestation brasero convivial</h2>
    <router-link to="/product/brasero">
      <button class="preview__btn default-btn">Voir le produit</button>
    </router-link>
  </article>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ZX7Preview"
};
</script>

<style lang="scss" scoped>
.preview {
  margin: 2.4rem auto;
  width: 32.7rem;
  border-radius: 0.8rem;
  background: url("../../assets/home/tablet/brasero_2.png");
  padding: 10.1rem 0 10.1rem 2.4rem;
  background-position: center;
  background-size: cover;
  color: white;

  @media (min-width: 768px) {
    background: url("../../assets/home/tablet/brasero_2.png");
    padding: 10.1rem 0 10.1rem 6.2rem;
    background-size: cover;
    background-position: center;
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    margin: 4.8rem auto;
    width: 111rem;
    padding: 10.1rem 0 10.1rem 9.5rem;
    background: url("../../assets/home/desktop/brasero_2.png");
    background-size: cover;
    background-position: center;
  }

  &__header {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.825rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
  }

  &__btn {
    margin-top: 3.2rem;
    background: none;
    color: white;
    border: 0.2rem solid white;
    transition: all 0.3s ease;

    &:hover {
      background: black;
      color: white;
    }
  }
}
</style>
